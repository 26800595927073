import React, { useContext } from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import { transformTableData } from '../utils/transformTable'
import Column from '../layouts/Column'
import { graphql } from 'gatsby'
import PagePadding from '../components/PagePadding/PagePadding'
import ReactHtmlParser from 'react-html-parser'
import InfoBlockCourse from '../components/InfoBlockCourse/InfoBlockCourse'
import TimeOverview from '../components/TimeOverview/TimeOverview'
import Clock from '../images/icons/Clock'
import Age from '../images/icons/Age'
import SignUpFormModal, {
	SignUpFormContextProvider,
} from '../components/SignUpFormModal/SignUpFormModal'
import Course from '../components/SignUpForms/Course/Course'

import HeroBigAsset from '../components/HeroBigAsset/HeroBigAsset'
import FullWidthImageCarousel from '../components/FullWidthImageCarousel/FullWidthImageCarousel'
import { HeaderContext } from '../components/Header/Header'
import { formatDate } from '../components/EventItems/EventItems'

export default function Special(props) {
	const wordpressAcfActionTryout = props.data.wordpressAcfActionTryout
	const HeaderCTX = useContext(HeaderContext)

	const Info = {
		title: wordpressAcfActionTryout.acf.title
			? wordpressAcfActionTryout.acf.title
			: wordpressAcfActionTryout.acf.title_course
			? wordpressAcfActionTryout.acf.title_course
			: '',
		course_description: wordpressAcfActionTryout.acf.description,
		information: [
			{
				title: wordpressAcfActionTryout.acf.about_course.title_1,
				content: ReactHtmlParser(
					wordpressAcfActionTryout.acf.about_course.paragraph_1,
				),
			},
			{
				title: wordpressAcfActionTryout.acf.about_course.title_2,
				content: ReactHtmlParser(
					wordpressAcfActionTryout.acf.about_course.paragraph_2,
				),
			},
			{
				title: wordpressAcfActionTryout.acf.about_course.title_3,
				content: ReactHtmlParser(
					wordpressAcfActionTryout.acf.about_course.paragraph_3,
				),
			},
		],
		specs: [
			{
				label: `${wordpressAcfActionTryout.acf.lesson_amount} lessen van ${wordpressAcfActionTryout.acf.lesson_duration} uur`,
				icon: <Clock winter={HeaderCTX.winterTime} />,
			},
			{
				label:
					wordpressAcfActionTryout.acf.age >= 17
						? 'Volwassenen'
						: !wordpressAcfActionTryout.acf.age
						? 'Iedereen'
						: 'Jeugd',
				icon: (
					<Age
						winter={HeaderCTX.winterTime}
						text={wordpressAcfActionTryout.acf.age}
					/>
				),
			},
		],
		data: {
			price: `${wordpressAcfActionTryout.acf.kosten}`,
			start_date: `${formatDate(
				wordpressAcfActionTryout.acf.start_date,
			)}`,
		},
		//	TO DO : PERIOD AND PRICE!
	}

	const SecondInfo = {
		course_description: wordpressAcfActionTryout.acf.description,
	}
	const days =
		wordpressAcfActionTryout.acf.times &&
		wordpressAcfActionTryout.acf.times !== null &&
		wordpressAcfActionTryout.acf.times.body &&
		wordpressAcfActionTryout.acf.times.body.length
			? wordpressAcfActionTryout.acf.times.body
			: []

	const headers =
		wordpressAcfActionTryout.acf.times &&
		wordpressAcfActionTryout.acf.times !== null &&
		wordpressAcfActionTryout.acf.times.header &&
		wordpressAcfActionTryout.acf.times.header.length
			? wordpressAcfActionTryout.acf.times.header.map(
					(header) => header.c,
			  )
			: []

	const daysFormatted = transformTableData(days, headers)

	return (
		<SignUpFormContextProvider>
			<SecondaryLayout>
				{wordpressAcfActionTryout.acf.pictures &&
				wordpressAcfActionTryout.acf.pictures[0] &&
				wordpressAcfActionTryout.acf.pictures[0].source_url ? (
					<HeroBigAsset
						image={{
							small:
								wordpressAcfActionTryout.acf.pictures &&
								wordpressAcfActionTryout.acf.pictures[0] &&
								wordpressAcfActionTryout.acf.pictures[0]
									.source_url
									? wordpressAcfActionTryout.acf.pictures[0]
											.source_url
									: '/images/inbalans-image-10.jpg',
							large:
								wordpressAcfActionTryout.acf.pictures &&
								wordpressAcfActionTryout.acf.pictures[0] &&
								wordpressAcfActionTryout.acf.pictures[0]
									.source_url
									? wordpressAcfActionTryout.acf.pictures[0]
											.source_url
									: '/images/inbalans-image-10.jpg',
							alt:
								wordpressAcfActionTryout.acf.pictures &&
								wordpressAcfActionTryout.acf.pictures[0] &&
								wordpressAcfActionTryout.acf.pictures[0]
									.alt_text &&
								wordpressAcfActionTryout.acf.pictures[0]
									.alt_text.length > 2
									? wordpressAcfActionTryout.acf.pictures[0]
											.alt_text
									: 'Cursus bij In Balans Alkmaar',
						}}
						course={true}
					/>
				) : null}

				<PagePadding>
					<Column>
						<InfoBlockCourse course={Info} />

						{wordpressAcfActionTryout.acf.times !== null ? (
							<Column>
								{daysFormatted.length > 0 ||
									(days.length > 0 && (
										<TimeOverview days={daysFormatted} />
									))}
							</Column>
						) : null}

						<InfoBlockCourse course={SecondInfo} />
					</Column>
				</PagePadding>
				{wordpressAcfActionTryout.acf.pictures &&
				wordpressAcfActionTryout.acf.pictures.length > 2 ? (
					<div>
						<FullWidthImageCarousel
							className={'full-width__image-carousel'}
							images={wordpressAcfActionTryout.acf.pictures}
						/>
					</div>
				) : null}
			</SecondaryLayout>
			<SignUpFormModal>
				<Course
					courseData={wordpressAcfActionTryout}
					days={daysFormatted}
				/>
			</SignUpFormModal>
		</SignUpFormContextProvider>
	)
}

export const query = graphql`
	query($wordpress_id: Int!) {
		wordpressAcfActionTryout(wordpress_id: { eq: $wordpress_id }) {
			id
			acf {
				cursusnaam
				soort
				periode
				cursusnaam
				amount
				age
				description
				end_date
				kosten
				kosten2
				lesson_amount
				lesson_duration
				tonen
				soort
				start_date
				title
				# times {
				# 	caption
				# 	header {
				# 		c
				# 	}
				# 	body {
				# 		c
				# 	}
				# }
				pictures {
					alt_text
					source_url
					template
					title
					type
					wordpress_id
					status
				}
				about_course {
					paragraph_1
					paragraph_2
					paragraph_3
					title_1
					title_2
					title_3
				}
				tonen_op_homepage
				promotie_detail {
					amount
					description
					price
				}
			}
			wordpress_id
		}
	}
`
